/**
 * Main store function
 */
import { createStore } from 'redux';
import rootReducer from './reducers';

export function configureStore(initialState = {}) {
     // Middleware and store enhancers
     const store = createStore(
          rootReducer,
          initialState
     );
     return store;
}