import { RESET_GENERAL_STATE, PREVIEW_STATE, SET_TRACKING_CODE, SET_TRACKING_PHONE } from '../actions/types';

export const INITIAL_STATE = {
     currentTab: null,
     isPreview: false,
};

const generalReducer = (state = INITIAL_STATE, action) => {
     switch (action.type) {
          case RESET_GENERAL_STATE: {
               const payload = action.payload;

               if (payload) {
                    return { ...state, ...payload };
               }

               return INITIAL_STATE;
          }

          case PREVIEW_STATE: {
               return {
                    ...state,
                    isPreview: action.payload,
               };
          }

          case SET_TRACKING_CODE: {
               const trackingCode = action.payload;

               if (trackingCode) {
                    return { ...state, trackingCode };
               }

               return INITIAL_STATE;
          }

          case SET_TRACKING_PHONE: {
               const trackingPhone = action.payload;

               if (trackingPhone) {
                    return { ...state, trackingPhone };
               }

               return INITIAL_STATE;
          }

          default:
               return state;
     }
};

export default generalReducer;
