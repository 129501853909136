exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-breed-detail-js": () => import("./../../../src/pages/preview-breed-detail/[...].js" /* webpackChunkName: "component---src-pages-preview-breed-detail-js" */),
  "component---src-pages-preview-condition-js": () => import("./../../../src/pages/preview-condition/[...].js" /* webpackChunkName: "component---src-pages-preview-condition-js" */),
  "component---src-pages-preview-page-badge-js": () => import("./../../../src/pages/preview-page-badge/[...].js" /* webpackChunkName: "component---src-pages-preview-page-badge-js" */),
  "component---src-pages-preview-page-js": () => import("./../../../src/pages/preview-page/[...].js" /* webpackChunkName: "component---src-pages-preview-page-js" */),
  "component---src-templates-badge-detail-template-js": () => import("./../../../src/templates/badgeDetail-template.js" /* webpackChunkName: "component---src-templates-badge-detail-template-js" */),
  "component---src-templates-breed-detail-template-js": () => import("./../../../src/templates/breedDetail-template.js" /* webpackChunkName: "component---src-templates-breed-detail-template-js" */),
  "component---src-templates-condition-detail-template-js": () => import("./../../../src/templates/conditionDetail-template.js" /* webpackChunkName: "component---src-templates-condition-detail-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

